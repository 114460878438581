'use client'

import { useSession, signOut } from 'next-auth/react'
import { useState } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useCommonContext } from '@/contexts/CommonContext'
import LoginModal from './loginModal'
import { User, LogIn } from 'lucide-react'

export default function LoginButton() {
    const { data: session, status } = useSession()
    const [commonContext, setCommonContext] = useCommonContext()
    const [open, setOpen] = useState(false)

    const handleOpenModal = () => {
        setCommonContext({ ...commonContext, loginModalOpen: true, loginModalAction: 'login' })
    }

    if (status === 'unauthenticated' || status === 'loading') {
        return (
            <button onClick={handleOpenModal} className='h-8 sm:h-10 sm:pl-3 sm:pr-4 sm:w-fit w-8 shadow-sm transition-all border border-white bg-gradient-to-r from-indigo-400 to-violet-500 text-violet-100 text-sm rounded-full flex items-center justify-center gap-1 text-nowrap'>
                <LogIn className='size-4 sm:size-5' />
                <span className='hidden sm:block'>
                    Log In
                </span>
            </button>
        )
    }

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
                <Avatar className="h-8 sm:h-9 sm:w-9 w-8 cursor-pointer">
                    <AvatarImage src={session?.user?.image || undefined} alt={session?.user?.name || "User avatar"} />
                    <AvatarFallback>{session?.user?.name?.[0] || <User className="h-5 w-5" />}</AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 z-[99]" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">{session?.user?.name}</p>
                        <p className="text-xs leading-none text-muted-foreground">{session?.user?.email}</p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => signOut()}>
                        Log out
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}