'use client'
import React, { createContext, SetStateAction, useState } from 'react'

type CommonContextType = {
    loginModalOpen: boolean,
    loginModalAction: 'login' | 'imageGen'
}

const CommonContext = createContext<[CommonContextType, React.Dispatch<SetStateAction<CommonContextType>>] | null>(null)

export default function CommonContextProvider({ children }: { children: React.ReactNode }) {

    const [value, setValue] = useState<CommonContextType>({
        loginModalOpen: false,
        loginModalAction: 'login'
    })

    return (
        <CommonContext.Provider value={[value, setValue]}>
            {children}
        </CommonContext.Provider>
    )
}


export const useCommonContext = () => {
    const context = React.useContext(CommonContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}