type ListResponse<T> = {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export enum PredictionStatus {
    PENDING = 'Pending',
    SUCCESS = 'Success',
    FAILED = 'Failed'
}

export type PredictionObject = {
    id: number
    status: PredictionStatus
    output: PredictionImageType[]
}

export type PredictionImageType = {
    image: string | null
}

export type UploadedImageType = {
    image: string
}

export type ProductList = ListResponse<ProductListItem>

export type PaymentProductObject = {
    boundingBoxFront: BoundingBoxFromAPI
    boundingBoxBack: BoundingBoxFromAPI | null
    name: string;
    price: number;
    MRP: number;
    color: ColorFromAPI;
}

export type PaymentObject = {
    color: string,
    size: string,
    data: string,
    item: PaymentProductObject
    cameraBox: BoundingBoxFromAPI | null
    isCircular: boolean
}

export type ProductListItem = {
    slug: string;
    name: string;
    price: number;
    image: string | null
    category: ProductItemCategory;
    colors: ColorFromAPI[];
    initialSize: string
    url: string;
    itemsAvailable: number;
    MRP: number;
    material: string
    initialColor: string
}
export type ProductDetail = {
    slug: string;
    colors: ColorFromAPI[];
    sizes: SizeFromAPI[];
    boundingBoxFront: BoundingBoxFromAPI;
    boundingBoxBack: BoundingBoxFromAPI | null
    MRP: number;
    itemsAvailable: number;
    name: string;
    material: string;
    image: string;
    description: string;
    price: number;
    category: ProductItemCategory;
    additionalDetails: string[];
    sizeChart: string | null
    metaTitle: string | null
    metaDescription: string | null
    initialScale: number
    initialX: number | null
    initialY: number | null
}

export type BoundingBoxFromAPI = {
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;
};

export enum ColorType {
    LIGHT = 'Light',
    DARK = 'Dark',
    BRIGHT = 'Bright'
}

export type ColorFromAPI = {
    id: number;
    images: ImageFromAPI[];
    sizes: SizeFromAPI[];
    name: string;
    hexCode: string;
    colorType: ColorType;
    isAvailable: boolean;
};

export type SampleImageResponse = ListResponse<SampleImage>

export type SampleImage = {
    id: number;
    image: string;
    category: ProductItemCategory;
}

export type ImageFromAPI = {
    id: number;
    image: string;
    altText: string;
    side: ImageSide
};

export enum ImageSide {
    FRONT = "Front",
    BACK = "Back",
    LEFT = "Left",
    RIGHT = "Right",
}

export enum ProductItemCategory {
    MEN = "Men",
    WOMEN = "Women",
    BOYS = "Boys",
    GIRLS = "Girls",
    ACCESSORIES = 'Accessories'
}

export type SizeFromAPI = {
    id: number;
    name: string;
    abbreviation: string;
    cameraBox: BoundingBoxFromAPI | null
    isCircular: boolean
};
