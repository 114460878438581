export const Properties = {
    defaultImgScale: 1.2,
    mockupGeneratorProductID: "terry-oversized-t-shirt-men",
    routes: {
        PRODUCT_LIST: "/products/ai-generated",
        EXPLORE: "/explore",
        EXPLORE_DESIGNS_WITH_TSHIRTS: "/explore-ai-generated-t-shirt-designs",
        HOME: "/",
        tools: {
            MOCKUP_GENERATOR: "/tools/ai-t-shirt-design-mockup-generator",
            MOCKUP_VIEWER: "/tools/t-shirt-design-mockup-viewer",
        }
    },
    domain: process.env.NODE_ENV == 'production' ? "https://www.flashyfox.in" : "http://localhost:3000",
}