'use client'
import { ColorFromAPI, ColorType, SampleImage } from "@/utils/APIRouteTypes"
import { useSearchParams } from "next/navigation"
import { createContext, ReactNode, useContext } from "react"
createContext

type NonUrlContextType = {
    colorMode: ColorType
    sampleImages: SampleImage[]
}

const NonUrlContext = createContext<NonUrlContextType | undefined>(undefined)

type ProviderProps = {
    children: ReactNode,
    colors: ColorFromAPI[],
    sampleImages: SampleImage[]
}

export const NonUrlContextProvider = (props: ProviderProps) => {
    const searchParams = useSearchParams()
    const selectedColor = searchParams.get('color') || (props.colors.length > 0 ? props.colors[0].name : '')
    const color = props.colors.find((color) => color.name === selectedColor)

    return (
        <NonUrlContext.Provider value={{ colorMode: color?.colorType || ColorType.BRIGHT, sampleImages: props.sampleImages }}>
            {props.children}
        </NonUrlContext.Provider>
    )
}

export const useNonUrlContext = () => {
    const context = useContext(NonUrlContext)
    if (context === undefined) {
        return {
            colorMode: ColorType.BRIGHT,
            sampleImages: []
        }
    }
    return context
}